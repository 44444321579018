import { Link, useNavigate } from 'react-router-dom';
import { Brain, Home, MessageSquare, Search, Users, BookOpen, Newspaper } from 'lucide-react';
import { Button } from '../ui/button';
import { ThemeToggle } from './theme-toggle';
import { MessageDropdown } from '../navigation/messages/message-dropdown';
import { NotificationDropdown } from '../navigation/notifications/notification-dropdown';
import { ProfileDropdown } from '../navigation/profile/profile-dropdown';
import { useAuthStore } from '@/lib/store';

export function Navbar() {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);

  return (
    <nav className="fixed top-0 z-50 w-full border-b border-white/20 bg-white/80 backdrop-blur-lg dark:bg-gray-900/80">
      <div className="container mx-auto flex h-16 items-center justify-between px-4">
        <div className="flex items-center gap-8">
          <Link to="/" className="text-xl font-bold">
            <span className="text-gradient">Connectia</span>
          </Link>
          <div className="hidden md:flex md:items-center md:gap-6">
            <Link to="/" className="flex items-center gap-2 text-gray-600 transition-colors hover:text-primary dark:text-gray-300 dark:hover:text-primary">
              <Home size={20} />
              <span>Home</span>
            </Link>
            <Link to="/news" className="flex items-center gap-2 text-gray-600 transition-colors hover:text-primary dark:text-gray-300 dark:hover:text-primary">
              <Newspaper size={20} />
              <span>News</span>
            </Link>
            <Link to="/forum" className="flex items-center gap-2 text-gray-600 transition-colors hover:text-primary dark:text-gray-300 dark:hover:text-primary">
              <BookOpen size={20} />
              <span>Forum</span>
            </Link>
            <Link to="/jobs" className="flex items-center gap-2 text-gray-600 transition-colors hover:text-primary dark:text-gray-300 dark:hover:text-primary">
              <Users size={20} />
              <span>Jobs</span>
            </Link>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <div className="relative hidden md:block">
            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
            <input
              type="search"
              placeholder="Search..."
              className="h-10 rounded-full bg-white/50 pl-10 pr-4 transition-all focus:outline-none focus:ring-2 focus:ring-primary/20 dark:bg-gray-800/50"
            />
          </div>
          {user ? (
            <>
              <MessageDropdown />
              <NotificationDropdown />
              <ThemeToggle />
              <ProfileDropdown />
            </>
          ) : (
            <>
              <ThemeToggle />
              <Button onClick={() => navigate('/auth')}>
                Sign In
              </Button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}