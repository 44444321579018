import { supabase } from '../supabase';

interface LikeResponse {
  action: 'liked' | 'unliked';
}

export async function createPost(data: { content: string; mediaUrls?: string[] }) {
  const { error } = await supabase
    .from('posts')
    .insert({
      content: data.content,
      media_urls: data.mediaUrls || [],
      user_id: (await supabase.auth.getUser()).data.user?.id,
    });

  if (error) throw error;
}

export async function getPosts() {
  const { data, error } = await supabase
    .from('posts')
    .select(`
      *,
      profiles:user_id (
        full_name,
        avatar_url,
        headline
      ),
      likes:likes(count),
      comments:comments(count)
    `)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
}

export async function toggleLike(contentId: string, contentType: 'post' | 'comment'): Promise<LikeResponse> {
  try {
    const user = await supabase.auth.getUser();
    if (!user.data.user) {
      throw new Error('Must be logged in to like content');
    }

    // Check if like exists
    const { data: existingLike } = await supabase
      .from('content_likes')
      .select('id')
      .match({ 
        user_id: user.data.user.id, 
        content_id: contentId,
        content_type: contentType 
      })
      .single();

    if (existingLike) {
      // Remove like
      const { error: deleteError } = await supabase
        .from('content_likes')
        .delete()
        .match({ 
          user_id: user.data.user.id, 
          content_id: contentId,
          content_type: contentType 
        });

      if (deleteError) throw deleteError;

      // Decrement likes count
      const { error: updateError } = await supabase.rpc(
        'decrement_likes_count',
        { 
          p_content_id: contentId,
          p_content_type: contentType 
        }
      );

      if (updateError) throw updateError;

      return { action: 'unliked' };
    } else {
      // Add new like
      const { error: insertError } = await supabase
        .from('content_likes')
        .insert({
          user_id: user.data.user.id,
          content_id: contentId,
          content_type: contentType
        });

      if (insertError) throw insertError;

      // Increment likes count
      const { error: updateError } = await supabase.rpc(
        'increment_likes_count',
        { 
          p_content_id: contentId,
          p_content_type: contentType 
        }
      );

      if (updateError) throw updateError;

      return { action: 'liked' };
    }
  } catch (error) {
    console.error('Error toggling like:', error);
    throw error;
  }
}

export async function checkLikeStatus(contentId: string, contentType: 'post' | 'comment'): Promise<boolean> {
  try {
    const user = await supabase.auth.getUser();
    if (!user.data.user) return false;

    const { data } = await supabase
      .from('content_likes')
      .select('id')
      .match({ 
        user_id: user.data.user.id, 
        content_id: contentId,
        content_type: contentType 
      })
      .single();

    return !!data;
  } catch (error) {
    console.error('Error checking like status:', error);
    return false;
  }
}

export async function createConnection(addresseeId: string) {
  const { error } = await supabase
    .from('connections')
    .insert({
      requester_id: (await supabase.auth.getUser()).data.user?.id,
      addressee_id: addresseeId,
    });

  if (error) throw error;
}

export async function updateConnectionStatus(connectionId: string, status: 'accepted' | 'rejected') {
  const { error } = await supabase
    .from('connections')
    .update({ status })
    .eq('id', connectionId);

  if (error) throw error;
}