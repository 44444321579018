import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { Upload, FileText, Send } from 'lucide-react';
import { applyForJob } from '@/lib/api/jobs';
import toast from 'react-hot-toast';

const applicationSchema = z.object({
  coverLetter: z.string()
    .min(100, 'Cover letter must be at least 100 characters')
    .max(5000, 'Cover letter must not exceed 5000 characters'),
  resumeUrl: z.string().url().optional(),
  availability: z.string().min(1, 'Please specify your availability'),
  expectedSalary: z.string().min(1, 'Please specify your expected salary'),
  noticePeriod: z.string().min(1, 'Please specify your notice period'),
  additionalInfo: z.string().optional(),
});

type ApplicationFormData = z.infer<typeof applicationSchema>;

interface JobApplicationFormProps {
  jobId: string;
  jobTitle: string;
  companyName: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export function JobApplicationForm({
  jobId,
  jobTitle,
  companyName,
  onSuccess,
  onCancel
}: JobApplicationFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm<ApplicationFormData>({
    resolver: zodResolver(applicationSchema)
  });

  const onSubmit = async (data: ApplicationFormData) => {
    try {
      setIsSubmitting(true);
      await applyForJob(jobId, {
        coverLetter: data.coverLetter,
        resumeUrl: data.resumeUrl,
        answers: {
          availability: data.availability,
          expectedSalary: data.expectedSalary,
          noticePeriod: data.noticePeriod,
          additionalInfo: data.additionalInfo
        }
      });
      toast.success('Application submitted successfully!');
      onSuccess();
    } catch (error) {
      toast.error('Failed to submit application');
      console.error('Application error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="p-6">
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Apply for {jobTitle}</h2>
        <p className="mt-1 text-gray-600">at {companyName}</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Cover Letter
          </label>
          <textarea
            {...register('coverLetter')}
            className="mt-1 h-48 w-full rounded-lg border p-3 focus:border-primary focus:outline-none focus:ring-1"
            placeholder="Introduce yourself and explain why you're a great fit for this role..."
          />
          {errors.coverLetter && (
            <p className="mt-1 text-sm text-red-600">{errors.coverLetter.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Resume/CV
          </label>
          <div className="mt-1">
            <Button
              type="button"
              variant="secondary"
              className="gap-2"
              onClick={() => {
                // Handle resume upload
                toast.error('Resume upload not implemented');
              }}
            >
              <Upload className="h-4 w-4" />
              Upload Resume
            </Button>
            <p className="mt-2 text-sm text-gray-500">
              Supported formats: PDF, DOC, DOCX (max 5MB)
            </p>
          </div>
        </div>

        <div className="grid gap-6 sm:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Availability
            </label>
            <input
              {...register('availability')}
              className="mt-1 w-full rounded-lg border p-2 focus:border-primary focus:outline-none focus:ring-1"
              placeholder="When can you start?"
            />
            {errors.availability && (
              <p className="mt-1 text-sm text-red-600">{errors.availability.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Expected Salary
            </label>
            <input
              {...register('expectedSalary')}
              className="mt-1 w-full rounded-lg border p-2 focus:border-primary focus:outline-none focus:ring-1"
              placeholder="Your expected salary range"
            />
            {errors.expectedSalary && (
              <p className="mt-1 text-sm text-red-600">{errors.expectedSalary.message}</p>
            )}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Notice Period
          </label>
          <input
            {...register('noticePeriod')}
            className="mt-1 w-full rounded-lg border p-2 focus:border-primary focus:outline-none focus:ring-1"
            placeholder="How soon can you join after offer acceptance?"
          />
          {errors.noticePeriod && (
            <p className="mt-1 text-sm text-red-600">{errors.noticePeriod.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Additional Information
          </label>
          <textarea
            {...register('additionalInfo')}
            className="mt-1 h-32 w-full rounded-lg border p-3 focus:border-primary focus:outline-none focus:ring-1"
            placeholder="Any additional information you'd like to share..."
          />
        </div>

        <div className="flex justify-end gap-4">
          <Button
            type="button"
            variant="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSubmitting}
            className="gap-2"
          >
            {isSubmitting ? (
              'Submitting...'
            ) : (
              <>
                <Send className="h-4 w-4" />
                Submit Application
              </>
            )}
          </Button>
        </div>
      </form>
    </Card>
  );
}