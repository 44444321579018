import { useState } from 'react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { JobMatchScore } from './job-match-score';
import { SkillGapAnalysis } from './skill-gap-analysis';
import { CareerInsights } from './career-insights';
import { SalaryInsights } from './salary-insights';
import { Brain, Target, DollarSign, BookOpen } from 'lucide-react';

export interface JobMatchingDashboardProps {
  jobId: string;
  jobTitle: string;
  companyName: string;
  requiredSkills: {
    name: string;
    level: 'required' | 'preferred';
    yearsRequired?: number;
    userProficiency?: number;
  }[];
  matchScore: {
    overall: number;
    skills: number;
    experience: number;
    requirements: number;
    culture: number;
  };
  careerInsights: {
    marketDemand: number;
    salaryTrends: {
      trend: 'up' | 'down' | 'stable';
      percentage: number;
      averageSalary: number;
    };
    competitionLevel: number;
    growthPotential: number;
    topCompanies: string[];
    requiredSkills: {
      name: string;
      demandScore: number;
    }[];
  };
  salaryInsights: {
    current: {
      min: number;
      max: number;
      currency: string;
    };
    market: {
      average: number;
      range: {
        min: number;
        max: number;
      };
      trend: 'up' | 'down' | 'stable';
      trendPercentage: number;
    };
    byLocation: {
      location: string;
      salary: number;
    }[];
    byCompanySize: {
      size: string;
      salary: number;
    }[];
    benefits: {
      type: string;
      percentage: number;
    }[];
  };
}

export function JobMatchingDashboard({
  jobId,
  jobTitle,
  companyName,
  requiredSkills,
  matchScore,
  careerInsights,
  salaryInsights
}: JobMatchingDashboardProps) {
  const [activeTab, setActiveTab] = useState<'match' | 'skills' | 'career' | 'salary'>('match');

  const handleViewResources = (skill: string) => {
    // Implement resource viewing logic
    console.log('View resources for:', skill);
  };

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <div className="mb-6">
          <h2 className="text-xl font-semibold">{jobTitle}</h2>
          <p className="mt-1 text-gray-600">{companyName}</p>
        </div>

        <div className="flex flex-wrap gap-4">
          <Button
            variant={activeTab === 'match' ? 'default' : 'ghost'}
            onClick={() => setActiveTab('match')}
            className="gap-2"
          >
            <Brain className="h-4 w-4" />
            Match Analysis
          </Button>
          <Button
            variant={activeTab === 'skills' ? 'default' : 'ghost'}
            onClick={() => setActiveTab('skills')}
            className="gap-2"
          >
            <Target className="h-4 w-4" />
            Skills Gap
          </Button>
          <Button
            variant={activeTab === 'career' ? 'default' : 'ghost'}
            onClick={() => setActiveTab('career')}
            className="gap-2"
          >
            <BookOpen className="h-4 w-4" />
            Career Insights
          </Button>
          <Button
            variant={activeTab === 'salary' ? 'default' : 'ghost'}
            onClick={() => setActiveTab('salary')}
            className="gap-2"
          >
            <DollarSign className="h-4 w-4" />
            Salary Insights
          </Button>
        </div>
      </Card>

      <div className="space-y-6">
        {activeTab === 'match' && (
          <JobMatchScore matchScore={matchScore} />
        )}

        {activeTab === 'skills' && (
          <SkillGapAnalysis
            requiredSkills={requiredSkills}
            onViewResources={handleViewResources}
          />
        )}

        {activeTab === 'career' && (
          <CareerInsights insights={careerInsights} />
        )}

        {activeTab === 'salary' && (
          <SalaryInsights insights={salaryInsights} />
        )}
      </div>
    </div>
  );
}