import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Job } from '@/types/job';
import { Button } from '@/components/ui/button';
import { Building2, MapPin, Calendar, Briefcase } from 'lucide-react';
import { useAuthStore } from '@/lib/store';
import { JobMatchingDashboard } from '@/components/jobs/job-matching';
import { JobApplicationForm } from '@/components/jobs/job-application-form';
import { JobApplicationStatus } from '@/components/jobs/job-application-status';
import { JobRecommendations } from '@/components/jobs/job-recommendations';
import toast from 'react-hot-toast';

export function JobDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState<Job | null>(null);
  const [loading, setLoading] = useState(true);
  const [showApplicationForm, setShowApplicationForm] = useState(false);
  const user = useAuthStore(state => state.user);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const { data, error } = await supabase
          .from('jobs')
          .select('*, company:companies(*)')
          .eq('id', id)
          .single();

        if (error) throw error;
        setJob(data);
      } catch (error) {
        console.error('Error fetching job:', error);
        toast.error('Failed to load job details');
      } finally {
        setLoading(false);
      }
    };

    fetchJob();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16">
        <div className="container mx-auto px-4 py-8">
          <div className="animate-pulse space-y-4">
            <div className="h-8 w-2/3 rounded bg-gray-200" />
            <div className="h-4 w-1/3 rounded bg-gray-200" />
            <div className="h-32 rounded bg-gray-200" />
          </div>
        </div>
      </div>
    );
  }

  if (!job) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16">
        <div className="container mx-auto px-4 py-8">
          <div className="text-center">
            <h1 className="text-2xl font-bold">Job not found</h1>
            <Button
              onClick={() => navigate('/jobs')}
              className="mt-4"
            >
              Back to Jobs
            </Button>
          </div>
        </div>
      </div>
    );
  }

  // Sample data for the matching dashboard
  const matchingData = {
    requiredSkills: job.skills.map(skill => ({
      name: skill,
      level: 'required' as const,
      yearsRequired: 2,
      userProficiency: Math.random() * 5
    })),
    matchScore: {
      overall: 85,
      skills: 80,
      experience: 90,
      requirements: 85,
      culture: 88
    },
    careerInsights: {
      marketDemand: 92,
      salaryTrends: {
        trend: 'up' as const,
        percentage: 15,
        averageSalary: 120000
      },
      competitionLevel: 65,
      growthPotential: 88,
      topCompanies: ['Google', 'Microsoft', 'Amazon', 'Meta', 'Apple'],
      requiredSkills: job.skills.map(skill => ({
        name: skill,
        demandScore: Math.floor(Math.random() * 30) + 70
      }))
    },
    salaryInsights: {
      current: {
        min: job.salary.min,
        max: job.salary.max,
        currency: job.salary.currency
      },
      market: {
        average: (job.salary.min + job.salary.max) / 2,
        range: {
          min: job.salary.min * 0.9,
          max: job.salary.max * 1.1
        },
        trend: 'up' as const,
        trendPercentage: 12
      },
      byLocation: [
        { location: 'San Francisco', salary: 150000 },
        { location: 'New York', salary: 140000 },
        { location: 'Seattle', salary: 135000 },
        { location: 'Austin', salary: 125000 }
      ],
      byCompanySize: [
        { size: 'Enterprise', salary: 145000 },
        { size: 'Mid-size', salary: 130000 },
        { size: 'Startup', salary: 120000 }
      ],
      benefits: [
        { type: 'Health Insurance', percentage: 95 },
        { type: 'Remote Work', percentage: 85 },
        { type: '401(k) Match', percentage: 80 },
        { type: 'Stock Options', percentage: 65 }
      ]
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="container mx-auto px-4 py-8">
        <div className="grid gap-8 lg:grid-cols-[1fr_300px]">
          <main className="space-y-6">
            {/* Job Details */}
            <div className="rounded-lg border bg-white p-8 shadow-sm">
              <div className="flex items-start gap-6">
                {job.company.logo ? (
                  <img
                    src={job.company.logo}
                    alt={job.company.name}
                    className="h-16 w-16 rounded-lg object-cover"
                  />
                ) : (
                  <div className="flex h-16 w-16 items-center justify-center rounded-lg bg-primary/10">
                    <Building2 className="h-8 w-8 text-primary" />
                  </div>
                )}
                <div>
                  <h1 className="text-2xl font-bold">{job.title}</h1>
                  <p className="mt-1 text-lg text-gray-600">{job.company.name}</p>
                  <div className="mt-4 flex flex-wrap items-center gap-4">
                    <div className="flex items-center gap-2 text-gray-600">
                      <MapPin size={20} />
                      <span>{job.location}</span>
                    </div>
                    <div className="flex items-center gap-2 text-gray-600">
                      <Briefcase size={20} />
                      <span>{job.type}</span>
                    </div>
                    <div className="flex items-center gap-2 text-gray-600">
                      <Calendar size={20} />
                      <span>Posted {new Date(job.postedAt).toLocaleDateString()}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-8 space-y-6">
                <section>
                  <h2 className="text-xl font-semibold">Description</h2>
                  <p className="mt-2 whitespace-pre-wrap text-gray-600">{job.description}</p>
                </section>

                <section>
                  <h2 className="text-xl font-semibold">Requirements</h2>
                  <ul className="mt-2 list-inside list-disc space-y-2 text-gray-600">
                    {job.requirements.map((req, index) => (
                      <li key={index}>{req}</li>
                    ))}
                  </ul>
                </section>

                <section>
                  <h2 className="text-xl font-semibold">Responsibilities</h2>
                  <ul className="mt-2 list-inside list-disc space-y-2 text-gray-600">
                    {job.responsibilities.map((resp, index) => (
                      <li key={index}>{resp}</li>
                    ))}
                  </ul>
                </section>

                <section>
                  <h2 className="text-xl font-semibold">Required Skills</h2>
                  <div className="mt-2 flex flex-wrap gap-2">
                    {job.skills.map((skill, index) => (
                      <span
                        key={index}
                        className="rounded-full bg-primary/10 px-3 py-1 text-sm text-primary"
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                </section>

                {job.benefits.length > 0 && (
                  <section>
                    <h2 className="text-xl font-semibold">Benefits</h2>
                    <ul className="mt-2 list-inside list-disc space-y-2 text-gray-600">
                      {job.benefits.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                      ))}
                    </ul>
                  </section>
                )}
              </div>
            </div>

            {/* Job Matching Dashboard */}
            {user && (
              <JobMatchingDashboard
                jobId={job.id}
                jobTitle={job.title}
                companyName={job.company.name}
                requiredSkills={matchingData.requiredSkills}
                matchScore={matchingData.matchScore}
                careerInsights={matchingData.careerInsights}
                salaryInsights={matchingData.salaryInsights}
              />
            )}

            {/* Application Form */}
            {showApplicationForm && (
              <JobApplicationForm
                jobId={job.id}
                jobTitle={job.title}
                companyName={job.company.name}
                onSuccess={() => setShowApplicationForm(false)}
                onCancel={() => setShowApplicationForm(false)}
              />
            )}
          </main>

          <aside className="space-y-6">
            <div className="rounded-lg border bg-white p-6 shadow-sm">
              <div className="mb-4">
                <h3 className="font-semibold">Salary Range</h3>
                <p className="mt-2 text-xl font-bold text-gray-900">
                  {job.salary.currency} {job.salary.min.toLocaleString()} - {job.salary.max.toLocaleString()}
                </p>
                <p className="mt-1 text-sm text-gray-500">per year</p>
              </div>

              {user ? (
                <>
                  <JobApplicationStatus jobId={job.id} />
                  {!showApplicationForm && (
                    <Button 
                      className="mt-4 w-full"
                      onClick={() => setShowApplicationForm(true)}
                    >
                      Apply Now
                    </Button>
                  )}
                </>
              ) : (
                <Button 
                  className="w-full"
                  onClick={() => navigate('/auth')}
                >
                  Sign in to Apply
                </Button>
              )}
            </div>

            <JobRecommendations
              currentJobId={job.id}
              skills={job.skills}
            />
          </aside>
        </div>
      </div>
    </div>
  );
}