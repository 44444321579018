import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { Mail, Lock, User, Building2 } from 'lucide-react';
import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { FormField } from './form-field';
import { RoleSelector } from './role-selector';
import toast from 'react-hot-toast';
import { signIn, signUp } from '@/lib/api/auth';
import { useAuthStore } from '@/lib/store';
import { authSchema } from './validation';
import type { AuthFormData } from '@/types/auth';

export function AuthForm() {
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const setUser = useAuthStore((state) => state.setUser);

  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm<AuthFormData>({
    resolver: zodResolver(authSchema),
    defaultValues: {
      role: 'individual'
    }
  });

  const role = watch('role', 'individual');

  const onSubmit = async (data: AuthFormData) => {
    try {
      setIsLoading(true);

      if (isLogin) {
        const { user, session } = await signIn(data);
        if (user) {
          setUser(user);
          toast.success('Welcome back!');
          navigate('/');
        }
      } else {
        await signUp(data);
        toast.success('Please check your email to verify your account');
        reset();
        setIsLogin(true);
      }
    } catch (error) {
      console.error('Auth error:', error);
      toast.error(error instanceof Error ? error.message : 'Authentication failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="w-full max-w-md p-8">
      <div className="mb-8 text-center">
        <h1 className="text-2xl font-bold">
          {isLogin ? 'Welcome Back' : 'Create an Account'}
        </h1>
        <p className="mt-2 text-gray-600">
          {isLogin 
            ? 'Sign in to connect with the AI community'
            : 'Join the leading AI professional network'}
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        {!isLogin && (
          <RoleSelector
            role={role}
            onRoleChange={(role) => register('role').onChange({ target: { value: role } })}
          />
        )}

        {!isLogin && role === 'individual' && (
          <FormField
            label="Full Name"
            error={errors.fullName}
            icon={User}
          >
            <input
              {...register('fullName')}
              className="w-full rounded-md border py-2 pl-10 pr-3"
              placeholder="Enter your full name"
            />
          </FormField>
        )}

        {!isLogin && role === 'company' && (
          <FormField
            label="Company Name"
            error={errors.companyName}
            icon={Building2}
          >
            <input
              {...register('companyName')}
              className="w-full rounded-md border py-2 pl-10 pr-3"
              placeholder="Enter company name"
            />
          </FormField>
        )}

        <FormField
          label="Email"
          error={errors.email}
          icon={Mail}
        >
          <input
            {...register('email')}
            type="email"
            className="w-full rounded-md border py-2 pl-10 pr-3"
            placeholder="Enter your email"
          />
        </FormField>

        <FormField
          label="Password"
          error={errors.password}
          icon={Lock}
        >
          <input
            {...register('password')}
            type="password"
            className="w-full rounded-md border py-2 pl-10 pr-3"
            placeholder="Enter your password"
          />
        </FormField>

        {isLogin && (
          <div className="flex justify-end">
            <Button
              type="button"
              variant="ghost"
              size="sm"
              className="text-sm text-blue-600 hover:text-blue-800"
              onClick={() => navigate('/forgot-password')}
            >
              Forgot Password?
            </Button>
          </div>
        )}

        <Button
          type="submit"
          className="w-full"
          disabled={isLoading}
        >
          {isLoading
            ? 'Please wait...'
            : isLogin
              ? 'Sign In'
              : 'Create Account'}
        </Button>

        <div className="text-center">
          <Button
            type="button"
            variant="ghost"
            className="text-sm text-gray-600 hover:text-gray-800"
            onClick={() => setIsLogin(!isLogin)}
          >
            {isLogin
              ? "Don't have an account? Sign Up"
              : 'Already have an account? Sign In'}
          </Button>
        </div>
      </form>
    </Card>
  );
}