import { useEffect, useState } from 'react';
import { Card } from '../ui/card';
import { Button } from '../ui/button';
import { Sparkles, ArrowRight } from 'lucide-react';
import { Job } from '@/types/job';
import { fetchJobs } from '@/lib/api/jobs';
import { Link } from 'react-router-dom';

interface JobRecommendationsProps {
  currentJobId: string;
  skills: string[];
}

export function JobRecommendations({ currentJobId, skills }: JobRecommendationsProps) {
  const [recommendations, setRecommendations] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadRecommendations = async () => {
      try {
        const jobs = await fetchJobs({ skills });
        setRecommendations(
          jobs
            .filter(job => job.id !== currentJobId)
            .sort((a, b) => {
              // Calculate match score based on skill overlap
              const aMatch = a.skills.filter(skill => skills.includes(skill)).length;
              const bMatch = b.skills.filter(skill => skills.includes(skill)).length;
              return bMatch - aMatch;
            })
            .slice(0, 3)
        );
      } catch (error) {
        console.error('Error loading recommendations:', error);
      } finally {
        setLoading(false);
      }
    };

    loadRecommendations();
  }, [currentJobId, skills]);

  if (loading) {
    return (
      <Card className="p-6">
        <div className="space-y-4">
          <div className="h-6 w-48 animate-pulse rounded bg-gray-200"></div>
          <div className="space-y-3">
            {[1, 2, 3].map(i => (
              <div key={i} className="h-24 animate-pulse rounded bg-gray-100"></div>
            ))}
          </div>
        </div>
      </Card>
    );
  }

  if (recommendations.length === 0) return null;

  return (
    <Card className="p-6">
      <div className="mb-4 flex items-center gap-2">
        <Sparkles className="h-5 w-5 text-primary" />
        <h3 className="font-semibold">Similar Jobs</h3>
      </div>

      <div className="space-y-4">
        {recommendations.map(job => (
          <div
            key={job.id}
            className="flex items-center justify-between rounded-lg border p-4 transition-colors hover:bg-gray-50"
          >
            <div>
              <h4 className="font-medium">{job.title}</h4>
              <p className="text-sm text-gray-600">{job.company.name}</p>
              <div className="mt-2 flex flex-wrap gap-2">
                {job.skills.slice(0, 3).map(skill => (
                  <span
                    key={skill}
                    className="rounded-full bg-primary/10 px-2 py-0.5 text-xs text-primary"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>
            <Link to={`/jobs/${job.id}`}>
              <Button variant="ghost" size="sm" className="gap-1">
                View
                <ArrowRight className="h-4 w-4" />
              </Button>
            </Link>
          </div>
        ))}
      </div>
    </Card>
  );
}