import { Card } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { Brain, Target, Star, Users } from 'lucide-react';

interface JobMatchScoreProps {
  matchScore: {
    overall: number;
    skills: number;
    experience: number;
    requirements: number;
    culture: number;
  };
}

export function JobMatchScore({ matchScore }: JobMatchScoreProps) {
  const getScoreColor = (score: number) => {
    if (score >= 80) return 'text-green-600';
    if (score >= 60) return 'text-blue-600';
    return 'text-gray-600';
  };

  const getScoreBg = (score: number) => {
    if (score >= 80) return 'bg-green-100';
    if (score >= 60) return 'bg-blue-100';
    return 'bg-gray-100';
  };

  return (
    <Card className="p-6">
      <div className="flex items-center justify-between">
        <h3 className="font-semibold">Match Score</h3>
        <div className={"rounded-full " + getScoreBg(matchScore.overall) + " px-3 py-1"}>
          <span className={"text-lg font-bold " + getScoreColor(matchScore.overall)}>
            {matchScore.overall}%
          </span>
        </div>
      </div>

      <div className="mt-6 space-y-4">
        <div>
          <div className="mb-2 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Brain className="h-5 w-5 text-primary" />
              <span className="text-sm font-medium">Skills Match</span>
            </div>
            <span className="text-sm">{matchScore.skills}%</span>
          </div>
          <Progress value={matchScore.skills} />
        </div>

        <div>
          <div className="mb-2 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Star className="h-5 w-5 text-primary" />
              <span className="text-sm font-medium">Experience Match</span>
            </div>
            <span className="text-sm">{matchScore.experience}%</span>
          </div>
          <Progress value={matchScore.experience} />
        </div>

        <div>
          <div className="mb-2 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Target className="h-5 w-5 text-primary" />
              <span className="text-sm font-medium">Requirements Match</span>
            </div>
            <span className="text-sm">{matchScore.requirements}%</span>
          </div>
          <Progress value={matchScore.requirements} />
        </div>

        <div>
          <div className="mb-2 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Users className="h-5 w-5 text-primary" />
              <span className="text-sm font-medium">Culture Fit</span>
            </div>
            <span className="text-sm">{matchScore.culture}%</span>
          </div>
          <Progress value={matchScore.culture} />
        </div>
      </div>
    </Card>
  );
}