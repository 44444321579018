import { supabase } from '../supabase';
import { Job } from '@/types/job';
import { handleApiError } from '../utils/api-helpers';
import { config } from '@/lib/config';

interface FetchJobsParams {
  searchQuery?: string;
  filters?: {
    types?: string[];
    levels?: string[];
    locations?: string[];
    salary?: {
      min: number;
      max: number;
    };
    remote?: boolean;
  };
}

export async function fetchJobs(params: FetchJobsParams = {}): Promise<Job[]> {
  try {
    // Check if Supabase is configured
    if (!config.supabaseUrl || !config.supabaseAnonKey) {
      throw new Error('Supabase configuration is missing. Please connect to Supabase first.');
    }

    let query = supabase
      .from('jobs')
      .select(`
        *,
        company:companies (
          id,
          name,
          logo,
          industry,
          size,
          location,
          description,
          website,
          verified
        )
      `)
      .eq('status', 'published')
      .order('posted_at', { ascending: false });

    // Apply filters
    if (params.filters) {
      if (params.filters.types?.length) {
        query = query.in('type', params.filters.types);
      }

      if (params.filters.levels?.length) {
        query = query.in('level', params.filters.levels);
      }

      if (params.filters.locations?.length) {
        query = query.in('location', params.filters.locations);
      }

      if (params.filters.salary) {
        query = query
          .gte('salary->min', params.filters.salary.min)
          .lte('salary->max', params.filters.salary.max);
      }

      if (params.filters.remote !== undefined) {
        query = query.eq('remote', params.filters.remote);
      }
    }

    if (params.searchQuery) {
      query = query.or(`
        title.ilike.%${params.searchQuery}%,
        description.ilike.%${params.searchQuery}%,
        companies.name.ilike.%${params.searchQuery}%
      `);
    }

    const { data, error } = await query;

    if (error) {
      console.error('Supabase error:', error);
      throw error;
    }

    return data as Job[];
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw handleApiError(error);
  }
}

export async function fetchJobById(id: string): Promise<Job> {
  try {
    if (!config.supabaseUrl || !config.supabaseAnonKey) {
      throw new Error('Supabase configuration is missing. Please connect to Supabase first.');
    }

    const { data, error } = await supabase
      .from('jobs')
      .select(`
        *,
        company:companies (
          id,
          name,
          logo,
          industry,
          size,
          location,
          description,
          website,
          verified
        )
      `)
      .eq('id', id)
      .single();

    if (error) throw error;
    return data as Job;
  } catch (error) {
    console.error('Error fetching job:', error);
    throw handleApiError(error);
  }
}

export async function saveJob(jobId: string): Promise<void> {
  try {
    const { error } = await supabase
      .from('saved_jobs')
      .insert({
        job_id: jobId,
        user_id: (await supabase.auth.getUser()).data.user?.id
      });

    if (error) throw error;
  } catch (error) {
    console.error('Error saving job:', error);
    throw handleApiError(error);
  }
}

export async function unsaveJob(jobId: string): Promise<void> {
  try {
    const { error } = await supabase
      .from('saved_jobs')
      .delete()
      .match({
        job_id: jobId,
        user_id: (await supabase.auth.getUser()).data.user?.id
      });

    if (error) throw error;
  } catch (error) {
    console.error('Error unsaving job:', error);
    throw handleApiError(error);
  }
}

export async function checkSavedStatus(jobId: string): Promise<boolean> {
  try {
    const { data, error } = await supabase
      .from('saved_jobs')
      .select('id')
      .match({
        job_id: jobId,
        user_id: (await supabase.auth.getUser()).data.user?.id
      })
      .single();

    if (error && error.code !== 'PGRST116') throw error; // PGRST116 is "no rows returned"
    return !!data;
  } catch (error) {
    console.error('Error checking saved status:', error);
    return false;
  }
}

export async function fetchApplicationStatus(jobId: string): Promise<string | null> {
  try {
    const { data, error } = await supabase
      .from('job_applications')
      .select('status')
      .match({
        job_id: jobId,
        user_id: (await supabase.auth.getUser()).data.user?.id
      })
      .single();

    if (error && error.code !== 'PGRST116') throw error;
    return data?.status || null;
  } catch (error) {
    console.error('Error fetching application status:', error);
    return null;
  }
}

export async function applyForJob(jobId: string, application: {
  coverLetter: string;
  resumeUrl?: string;
  answers: Record<string, any>;
}): Promise<void> {
  try {
    const { error } = await supabase
      .from('job_applications')
      .insert({
        job_id: jobId,
        user_id: (await supabase.auth.getUser()).data.user?.id,
        cover_letter: application.coverLetter,
        resume_url: application.resumeUrl,
        answers: application.answers,
        status: 'pending'
      });

    if (error) throw error;
  } catch (error) {
    console.error('Error applying for job:', error);
    throw handleApiError(error);
  }
}