import { useState, useEffect } from 'react';
import { Heart } from 'lucide-react';
import { Button } from '../ui/button';
import { toggleLike, checkLikeStatus } from '@/lib/api/social';
import { useAuthStore } from '@/lib/store';
import toast from 'react-hot-toast';

interface LikeButtonProps {
  contentId: string;
  contentType: 'post' | 'comment';
  initialLikes: number;
  onLikeChange?: (newCount: number) => void;
}

export function LikeButton({ 
  contentId, 
  contentType, 
  initialLikes,
  onLikeChange 
}: LikeButtonProps) {
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(initialLikes);
  const [isLoading, setIsLoading] = useState(false);
  const user = useAuthStore(state => state.user);

  useEffect(() => {
    const checkStatus = async () => {
      if (!user) return;
      const status = await checkLikeStatus(contentId, contentType);
      setIsLiked(status);
    };

    checkStatus();
  }, [contentId, contentType, user]);

  const handleLikeClick = async () => {
    if (!user) {
      toast.error('Please sign in to like content');
      return;
    }

    try {
      setIsLoading(true);
      const result = await toggleLike(contentId, contentType);
      
      setIsLiked(result.action === 'liked');
      const newCount = likesCount + (result.action === 'liked' ? 1 : -1);
      setLikesCount(newCount);
      onLikeChange?.(newCount);

    } catch (error) {
      console.error('Error toggling like:', error);
      toast.error('Failed to update like status');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      variant="ghost"
      size="sm"
      onClick={handleLikeClick}
      disabled={isLoading}
      className={"gap-2 " + (isLiked ? "text-red-500" : "")}
    >
      <Heart className={isLiked ? "fill-current" : ""} />
      <span>{likesCount}</span>
    </Button>
  );
}