import { z } from 'zod';

export const authSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string()
    .min(8, 'Password must be at least 8 characters')
    .regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .regex(/[a-z]/, 'Password must contain at least one lowercase letter')
    .regex(/[0-9]/, 'Password must contain at least one number'),
  role: z.enum(['individual', 'company'] as const),
  fullName: z.string().min(2, 'Full name is required').optional(),
  companyName: z.string().min(2, 'Company name is required').optional(),
}).refine((data) => {
  if (data.role === 'individual') {
    return !!data.fullName;
  }
  return !!data.companyName;
}, {
  message: 'Please provide required profile information',
  path: ['fullName'],
});
