import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { BookOpen, CheckCircle, AlertCircle } from 'lucide-react';

interface SkillGapAnalysisProps {
  requiredSkills: {
    name: string;
    level: 'required' | 'preferred';
    yearsRequired?: number;
    userProficiency?: number;
  }[];
  onViewResources: (skill: string) => void;
}

export function SkillGapAnalysis({ requiredSkills, onViewResources }: SkillGapAnalysisProps) {
  const getSkillStatus = (skill: typeof requiredSkills[0]) => {
    if (!skill.userProficiency) return 'missing';
    if (skill.yearsRequired && skill.userProficiency < skill.yearsRequired) return 'partial';
    return 'met';
  };

  const getRequirementText = (skill: typeof requiredSkills[0]) => {
    if (skill.yearsRequired) {
      return skill.yearsRequired + " years required";
    }
    return skill.level === 'required' ? 'Required skill' : 'Preferred skill';
  };

  const missingSkills = requiredSkills.filter(s => getSkillStatus(s) === 'missing');
  const partialSkills = requiredSkills.filter(s => getSkillStatus(s) === 'partial');
  const metSkills = requiredSkills.filter(s => getSkillStatus(s) === 'met');

  return (
    <Card className="p-6">
      <h3 className="mb-6 font-semibold">Skill Gap Analysis</h3>

      {metSkills.length > 0 && (
        <div className="mb-6">
          <h4 className="mb-3 text-sm font-medium text-green-600">Skills Met</h4>
          <div className="space-y-2">
            {metSkills.map(skill => (
              <div
                key={skill.name}
                className="flex items-center justify-between rounded-lg border border-green-200 bg-green-50 p-3"
              >
                <div className="flex items-center gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500" />
                  <span>{skill.name}</span>
                </div>
                <span className="text-sm text-green-600">
                  {skill.userProficiency} years
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      {partialSkills.length > 0 && (
        <div className="mb-6">
          <h4 className="mb-3 text-sm font-medium text-yellow-600">Skills to Improve</h4>
          <div className="space-y-2">
            {partialSkills.map(skill => (
              <div
                key={skill.name}
                className="flex items-center justify-between rounded-lg border border-yellow-200 bg-yellow-50 p-3"
              >
                <div className="flex items-center gap-2">
                  <AlertCircle className="h-5 w-5 text-yellow-500" />
                  <div>
                    <span>{skill.name}</span>
                    <p className="text-sm text-yellow-600">
                      {skill.userProficiency} of {skill.yearsRequired} years required
                    </p>
                  </div>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => onViewResources(skill.name)}
                  className="gap-2"
                >
                  <BookOpen className="h-4 w-4" />
                  Resources
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}

      {missingSkills.length > 0 && (
        <div>
          <h4 className="mb-3 text-sm font-medium text-red-600">Missing Skills</h4>
          <div className="space-y-2">
            {missingSkills.map(skill => (
              <div
                key={skill.name}
                className="flex items-center justify-between rounded-lg border border-red-200 bg-red-50 p-3"
              >
                <div className="flex items-center gap-2">
                  <AlertCircle className="h-5 w-5 text-red-500" />
                  <div>
                    <span>{skill.name}</span>
                    <p className="text-sm text-red-600">
                      {getRequirementText(skill)}
                    </p>
                  </div>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => onViewResources(skill.name)}
                  className="gap-2"
                >
                  <BookOpen className="h-4 w-4" />
                  Resources
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
    </Card>
  );
}