import { AuthForm } from '@/components/auth/auth-form';
import { NeuralBackground } from '@/components/shared/neural-background';

export function AuthPage() {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
      <NeuralBackground />
      <div className="relative w-full max-w-md">
        <AuthForm />
      </div>
    </div>
  );
}