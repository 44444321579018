import { useEffect, useState } from 'react';
import { Card } from '../ui/card';
import { Button } from '../ui/button';
import { Clock, CheckCircle, XCircle, AlertTriangle } from 'lucide-react';
import { fetchApplicationStatus } from '@/lib/api/jobs';

interface JobApplicationStatusProps {
  jobId: string;
  onViewApplication?: () => void;
}

export function JobApplicationStatus({ jobId, onViewApplication }: JobApplicationStatusProps) {
  const [status, setStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const applicationStatus = await fetchApplicationStatus(jobId);
        setStatus(applicationStatus);
      } catch (error) {
        console.error('Error fetching application status:', error);
      } finally {
        setLoading(false);
      }
    };

    checkStatus();
  }, [jobId]);

  if (loading) {
    return (
      <Card className="p-4">
        <div className="animate-pulse flex items-center justify-between">
          <div className="h-4 w-24 bg-gray-200 rounded"></div>
          <div className="h-4 w-32 bg-gray-200 rounded"></div>
        </div>
      </Card>
    );
  }

  if (!status) return null;

  const getStatusDetails = () => {
    switch (status) {
      case 'pending':
        return {
          icon: Clock,
          color: 'text-yellow-500',
          bgColor: 'bg-yellow-100',
          message: 'Application Under Review',
          description: 'Your application is being reviewed by the hiring team.'
        };
      case 'interviewing':
        return {
          icon: AlertTriangle,
          color: 'text-blue-500',
          bgColor: 'bg-blue-100',
          message: 'Interview Stage',
          description: 'You have been selected for interviews.'
        };
      case 'accepted':
        return {
          icon: CheckCircle,
          color: 'text-green-500',
          bgColor: 'bg-green-100',
          message: 'Application Accepted',
          description: 'Congratulations! Your application has been accepted.'
        };
      case 'rejected':
        return {
          icon: XCircle,
          color: 'text-red-500',
          bgColor: 'bg-red-100',
          message: 'Application Not Selected',
          description: 'Thank you for your interest. The position has been filled.'
        };
      default:
        return {
          icon: Clock,
          color: 'text-gray-500',
          bgColor: 'bg-gray-100',
          message: 'Application Submitted',
          description: 'Your application has been received.'
        };
    }
  };

  const { icon: Icon, color, bgColor, message, description } = getStatusDetails();

  return (
    <Card className="p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className={`rounded-full ${bgColor} p-2`}>
            <Icon className={`h-5 w-5 ${color}`} />
          </div>
          <div>
            <h3 className="font-medium">{message}</h3>
            <p className="text-sm text-gray-600">{description}</p>
          </div>
        </div>
        {onViewApplication && (
          <Button
            variant="ghost"
            onClick={onViewApplication}
          >
            View Details
          </Button>
        )}
      </div>
    </Card>
  );
}