import { Card } from '@/components/ui/card';
import { DollarSign, TrendingUp, MapPin, Building2 } from 'lucide-react';

interface SalaryInsightsProps {
  insights: {
    current: {
      min: number;
      max: number;
      currency: string;
    };
    market: {
      average: number;
      range: {
        min: number;
        max: number;
      };
      trend: 'up' | 'down' | 'stable';
      trendPercentage: number;
    };
    byLocation: {
      location: string;
      salary: number;
    }[];
    byCompanySize: {
      size: string;
      salary: number;
    }[];
    benefits: {
      type: string;
      percentage: number;
    }[];
  };
}

export function SalaryInsights({ insights }: SalaryInsightsProps) {
  return (
    <Card className="p-6">
      <h3 className="mb-6 font-semibold">Salary Insights</h3>

      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-lg border p-4">
          <div className="flex items-center gap-2">
            <DollarSign className="h-5 w-5 text-primary" />
            <span className="text-sm font-medium">Current Range</span>
          </div>
          <div className="mt-2">
            <p className="text-2xl font-bold">
              {insights.current.currency} {insights.current.min.toLocaleString()} - {insights.current.max.toLocaleString()}
            </p>
            <p className="mt-1 text-sm text-gray-600">Listed salary range</p>
          </div>
        </div>

        <div className="rounded-lg border p-4">
          <div className="flex items-center gap-2">
            <TrendingUp className="h-5 w-5 text-primary" />
            <span className="text-sm font-medium">Market Average</span>
          </div>
          <div className="mt-2">
            <p className="text-2xl font-bold">
              {insights.current.currency} {insights.market.average.toLocaleString()}
            </p>
            <p className="mt-1 text-sm text-gray-600">
              {insights.market.trend === 'up' && '+'}
              {insights.market.trendPercentage}% YoY
            </p>
          </div>
        </div>

        <div className="rounded-lg border p-4">
          <div className="flex items-center gap-2">
            <MapPin className="h-5 w-5 text-primary" />
            <span className="text-sm font-medium">Location Range</span>
          </div>
          <div className="mt-2">
            <p className="text-2xl font-bold">
              {insights.current.currency} {insights.market.range.min.toLocaleString()} - {insights.market.range.max.toLocaleString()}
            </p>
            <p className="mt-1 text-sm text-gray-600">Based on location</p>
          </div>
        </div>

        <div className="rounded-lg border p-4">
          <div className="flex items-center gap-2">
            <Building2 className="h-5 w-5 text-primary" />
            <span className="text-sm font-medium">Company Size Impact</span>
          </div>
          <div className="mt-2">
            <p className="text-2xl font-bold">
              {Math.round((insights.byCompanySize.reduce((acc, curr) => acc + curr.salary, 0) / insights.byCompanySize.length)).toLocaleString()}
            </p>
            <p className="mt-1 text-sm text-gray-600">Average by company size</p>
          </div>
        </div>
      </div>

      <div className="mt-8 grid gap-6 sm:grid-cols-2">
        <div>
          <h4 className="mb-3 text-sm font-medium">Salary by Location</h4>
          <div className="space-y-2">
            {insights.byLocation.map(location => (
              <div
                key={location.location}
                className="flex items-center justify-between rounded-lg bg-gray-50 p-3"
              >
                <span>{location.location}</span>
                <span className="font-medium">
                  {insights.current.currency} {location.salary.toLocaleString()}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h4 className="mb-3 text-sm font-medium">Common Benefits</h4>
          <div className="space-y-2">
            {insights.benefits.map(benefit => (
              <div
                key={benefit.type}
                className="flex items-center justify-between rounded-lg bg-gray-50 p-3"
              >
                <span>{benefit.type}</span>
                <span className="text-sm text-gray-600">
                  {benefit.percentage}% of companies
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
}