import { useEffect, useState } from 'react';
import { JobCard } from './job-card';
import { Briefcase } from 'lucide-react';
import { Job } from '@/types/job';
import { fetchJobs } from '@/lib/api/jobs';
import { config } from '@/lib/config';

interface JobListProps {
  searchQuery?: string;
  filters?: any;
}

export function JobList({ searchQuery, filters }: JobListProps) {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const loadJobs = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await fetchJobs({ searchQuery, filters });
        setJobs(data);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to load jobs'));
        console.error('Error loading jobs:', err);
      } finally {
        setLoading(false);
      }
    };

    if (config.supabaseUrl && config.supabaseAnonKey) {
      loadJobs();
    }
  }, [searchQuery, filters]);

  if (!config.supabaseUrl || !config.supabaseAnonKey) {
    return (
      <div className="rounded-lg border bg-white p-8 text-center">
        <Briefcase className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-4 text-lg font-medium">Connect to Supabase</h3>
        <p className="mt-2 text-gray-600">
          Please connect to Supabase to view job listings
        </p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="space-y-4">
        {[1, 2, 3].map((i) => (
          <div
            key={i}
            className="h-48 animate-pulse rounded-lg border bg-gray-100"
          />
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-lg border bg-red-50 p-4 text-red-800">
        <p>Failed to load jobs. Please try again later.</p>
      </div>
    );
  }

  if (jobs.length === 0) {
    return (
      <div className="rounded-lg border bg-white p-8 text-center">
        <Briefcase className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-4 text-lg font-medium">No jobs found</h3>
        <p className="mt-2 text-gray-600">
          Try adjusting your filters or check back later for new opportunities
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {jobs.map((job) => (
        <JobCard key={job.id} job={job} />
      ))}
    </div>
  );
}