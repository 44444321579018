import { Card } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { TrendingUp, Users, Target, DollarSign } from 'lucide-react';

interface CareerInsightsProps {
  insights: {
    marketDemand: number;
    salaryTrends: {
      trend: 'up' | 'down' | 'stable';
      percentage: number;
      averageSalary: number;
    };
    competitionLevel: number;
    growthPotential: number;
    topCompanies: string[];
    requiredSkills: {
      name: string;
      demandScore: number;
    }[];
  };
}

export function CareerInsights({ insights }: CareerInsightsProps) {
  const getTrendClass = (trend: 'up' | 'down' | 'stable') => {
    switch (trend) {
      case 'up':
        return 'text-green-600';
      case 'down':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <Card className="p-6">
      <h3 className="mb-6 font-semibold">Career Insights</h3>

      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <div>
          <div className="flex items-center gap-2">
            <TrendingUp className="h-5 w-5 text-primary" />
            <span className="text-sm font-medium">Market Demand</span>
          </div>
          <div className="mt-2">
            <div className="flex items-center justify-between">
              <span className="text-2xl font-bold">{insights.marketDemand}%</span>
              <span className="text-sm text-gray-600">Demand Score</span>
            </div>
            <Progress value={insights.marketDemand} className="mt-2" />
          </div>
        </div>

        <div>
          <div className="flex items-center gap-2">
            <DollarSign className="h-5 w-5 text-primary" />
            <span className="text-sm font-medium">Salary Trends</span>
          </div>
          <div className="mt-2">
            <div className="flex items-center justify-between">
              <span className="text-2xl font-bold">
                {insights.salaryTrends.trend === 'up' && '+'}
                {insights.salaryTrends.percentage}%
              </span>
              <span className={"text-sm " + getTrendClass(insights.salaryTrends.trend)}>
                YoY Change
              </span>
            </div>
            <p className="mt-1 text-sm text-gray-600">
              Avg: ${insights.salaryTrends.averageSalary.toLocaleString()}
            </p>
          </div>
        </div>

        <div>
          <div className="flex items-center gap-2">
            <Users className="h-5 w-5 text-primary" />
            <span className="text-sm font-medium">Competition Level</span>
          </div>
          <div className="mt-2">
            <div className="flex items-center justify-between">
              <span className="text-2xl font-bold">{insights.competitionLevel}%</span>
              <span className="text-sm text-gray-600">Competition</span>
            </div>
            <Progress value={insights.competitionLevel} className="mt-2" />
          </div>
        </div>

        <div>
          <div className="flex items-center gap-2">
            <Target className="h-5 w-5 text-primary" />
            <span className="text-sm font-medium">Growth Potential</span>
          </div>
          <div className="mt-2">
            <div className="flex items-center justify-between">
              <span className="text-2xl font-bold">{insights.growthPotential}%</span>
              <span className="text-sm text-gray-600">Potential</span>
            </div>
            <Progress value={insights.growthPotential} className="mt-2" />
          </div>
        </div>
      </div>

      <div className="mt-8 grid gap-6 sm:grid-cols-2">
        <div>
          <h4 className="mb-3 text-sm font-medium">Top Hiring Companies</h4>
          <div className="space-y-2">
            {insights.topCompanies.map((company, index) => (
              <div
                key={company}
                className="flex items-center justify-between rounded-lg bg-gray-50 p-3"
              >
                <span>{company}</span>
                <span className="text-sm text-gray-600">#{index + 1}</span>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h4 className="mb-3 text-sm font-medium">In-Demand Skills</h4>
          <div className="space-y-2">
            {insights.requiredSkills.map(skill => (
              <div key={skill.name}>
                <div className="flex items-center justify-between text-sm">
                  <span>{skill.name}</span>
                  <span>{skill.demandScore}% demand</span>
                </div>
                <Progress value={skill.demandScore} className="mt-1" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
}