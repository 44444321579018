import { Search, MapPin, DollarSign, Briefcase } from 'lucide-react';
import { Button } from '../ui/button';
import { Card } from '../ui/card';

interface JobSearchProps {
  value: string;
  onChange: (value: string) => void;
  onLocationSelect?: (location: string) => void;
  onSalarySelect?: (range: { min: number; max: number }) => void;
  onTypeSelect?: (type: string) => void;
}

export function JobSearch({ 
  value, 
  onChange,
  onLocationSelect,
  onSalarySelect,
  onTypeSelect
}: JobSearchProps) {
  return (
    <Card className="p-4">
      <div className="grid gap-4 md:grid-cols-[1fr_auto_auto_auto]">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
          <input
            type="search"
            placeholder="Search jobs by title, company, or keywords..."
            className="w-full rounded-lg border py-2 pl-10 pr-4 focus:border-primary focus:outline-none focus:ring-1"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>

        <Button 
          variant="ghost" 
          className="gap-2"
          onClick={() => onLocationSelect?.('all')}
        >
          <MapPin className="h-5 w-5" />
          Location
        </Button>

        <Button 
          variant="ghost" 
          className="gap-2"
          onClick={() => onSalarySelect?.({ min: 0, max: 200000 })}
        >
          <DollarSign className="h-5 w-5" />
          Salary
        </Button>

        <Button 
          variant="ghost" 
          className="gap-2"
          onClick={() => onTypeSelect?.('all')}
        >
          <Briefcase className="h-5 w-5" />
          Job Type
        </Button>
      </div>
    </Card>
  );
}