import { useState } from 'react';
import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { Briefcase, Users, MapPin, DollarSign, Globe, Sliders } from 'lucide-react';
import { JobType, ExperienceLevel } from '@/types/job';

interface JobFiltersProps {
  filters: {
    types: JobType[];
    levels: ExperienceLevel[];
    locations: string[];
    salary: {
      min: number;
      max: number;
    };
    remote: boolean;
    skills: string[];
  };
  onFilterChange: (filters: JobFiltersProps['filters']) => void;
}

const defaultSalary = {
  min: 0,
  max: 200000
};

export function JobFilters({ filters, onFilterChange }: JobFiltersProps) {
  const [expanded, setExpanded] = useState(false);

  const handleTypeChange = (type: JobType) => {
    const newTypes = filters.types.includes(type)
      ? filters.types.filter(t => t !== type)
      : [...filters.types, type];
    onFilterChange({ ...filters, types: newTypes });
  };

  const handleLevelChange = (level: ExperienceLevel) => {
    const newLevels = filters.levels.includes(level)
      ? filters.levels.filter(l => l !== level)
      : [...filters.levels, level];
    onFilterChange({ ...filters, levels: newLevels });
  };

  const handleSalaryChange = (max: number) => {
    onFilterChange({
      ...filters,
      salary: { ...filters.salary, max }
    });
  };

  return (
    <Card className="divide-y dark:divide-gray-700">
      <div className="p-4">
        <div className="flex items-center justify-between">
          <h3 className="font-semibold">Filters</h3>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setExpanded(!expanded)}
            className="gap-2"
          >
            <Sliders className="h-4 w-4" />
            {expanded ? 'Collapse' : 'Expand'}
          </Button>
        </div>
      </div>

      <div className={`space-y-4 p-4 ${expanded ? '' : 'hidden sm:block'}`}>
        <div>
          <h4 className="mb-3 flex items-center gap-2 font-medium">
            <Briefcase className="h-4 w-4" />
            Job Type
          </h4>
          <div className="space-y-2">
            {Object.values(JobType).map((type) => (
              <label key={type} className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={filters.types.includes(type)}
                  onChange={() => handleTypeChange(type)}
                  className="rounded border-gray-300"
                />
                <span className="text-sm">{type.replace('-', ' ').toUpperCase()}</span>
              </label>
            ))}
          </div>
        </div>

        <div>
          <h4 className="mb-3 flex items-center gap-2 font-medium">
            <Users className="h-4 w-4" />
            Experience Level
          </h4>
          <div className="space-y-2">
            {Object.values(ExperienceLevel).map((level) => (
              <label key={level} className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={filters.levels.includes(level)}
                  onChange={() => handleLevelChange(level)}
                  className="rounded border-gray-300"
                />
                <span className="text-sm">{level.toUpperCase()}</span>
              </label>
            ))}
          </div>
        </div>

        <div>
          <h4 className="mb-3 flex items-center gap-2 font-medium">
            <DollarSign className="h-4 w-4" />
            Salary Range
          </h4>
          <div className="space-y-4">
            <input
              type="range"
              min="0"
              max="200000"
              step="10000"
              value={filters.salary.max}
              onChange={(e) => handleSalaryChange(parseInt(e.target.value))}
              className="w-full"
            />
            <div className="flex justify-between text-sm">
              <span>${filters.salary.min.toLocaleString()}</span>
              <span>${filters.salary.max.toLocaleString()}+</span>
            </div>
          </div>
        </div>

        <div>
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={filters.remote}
              onChange={(e) => onFilterChange({ ...filters, remote: e.target.checked })}
              className="rounded border-gray-300"
            />
            <span className="flex items-center gap-2 text-sm">
              <Globe className="h-4 w-4" />
              Remote Only
            </span>
          </label>
        </div>

        {(filters.types.length > 0 || filters.levels.length > 0 || filters.remote) && (
          <Button
            variant="secondary"
            className="w-full"
            onClick={() => onFilterChange({
              types: [],
              levels: [],
              locations: [],
              salary: defaultSalary,
              remote: false,
              skills: [],
            })}
          >
            Clear All Filters
          </Button>
        )}
      </div>
    </Card>
  );
}