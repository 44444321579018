import { Button } from '../ui/button';
import { User, Building2 } from 'lucide-react';
import { UserRole } from '@/types/auth';

interface RoleSelectorProps {
  role: UserRole;
  onRoleChange: (role: UserRole) => void;
}

export function RoleSelector({ role, onRoleChange }: RoleSelectorProps) {
  return (
    <div className="flex gap-4">
      <Button
        type="button"
        variant={role === 'individual' ? 'primary' : 'secondary'}
        className="flex-1"
        onClick={() => onRoleChange('individual')}
      >
        <User className="mr-2 h-5 w-5" />
        Professional
      </Button>
      <Button
        type="button"
        variant={role === 'company' ? 'primary' : 'secondary'}
        className="flex-1"
        onClick={() => onRoleChange('company')}
      >
        <Building2 className="mr-2 h-5 w-5" />
        Company
      </Button>
    </div>
  );
}