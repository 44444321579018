import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Building2, MapPin, DollarSign, Briefcase, Clock, Bookmark } from 'lucide-react';
import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { Job } from '@/types/job';
import { formatDistanceToNow } from '@/lib/utils';
import { saveJob, unsaveJob, checkSavedStatus } from '@/lib/api/jobs';
import { useAuthStore } from '@/lib/store';
import toast from 'react-hot-toast';

interface JobCardProps {
  job: Job;
}

export function JobCard({ job }: JobCardProps) {
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useAuthStore(state => state.user);

  useEffect(() => {
    const checkSaved = async () => {
      if (!user) return;
      const saved = await checkSavedStatus(job.id);
      setIsSaved(saved);
    };

    checkSaved();
  }, [job.id, user]);

  const handleSaveToggle = async () => {
    if (!user) {
      toast.error('Please sign in to save jobs');
      return;
    }

    try {
      setIsLoading(true);
      if (isSaved) {
        await unsaveJob(job.id);
        toast.success('Job removed from saved jobs');
      } else {
        await saveJob(job.id);
        toast.success('Job saved successfully');
      }
      setIsSaved(!isSaved);
    } catch (error) {
      toast.error('Failed to update saved jobs');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="group overflow-hidden p-6 transition-all hover:shadow-lg">
      <div className="flex items-start gap-6">
        {job.company.logo ? (
          <img
            src={job.company.logo}
            alt={job.company.name}
            className="h-16 w-16 rounded-lg object-cover"
          />
        ) : (
          <div className="flex h-16 w-16 items-center justify-center rounded-lg bg-primary/10">
            <Building2 className="h-8 w-8 text-primary" />
          </div>
        )}

        <div className="flex-1">
          <div className="flex items-start justify-between">
            <div>
              <Link 
                to={`/jobs/${job.id}`}
                className="text-xl font-semibold hover:text-primary"
              >
                {job.title}
              </Link>
              <p className="mt-1 text-gray-600 dark:text-gray-400">
                {job.company.name}
                {job.company.verified && (
                  <span className="ml-2 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 dark:bg-green-900/30 dark:text-green-500">
                    Verified
                  </span>
                )}
              </p>
            </div>
            <Button
              variant="ghost"
              size="sm"
              onClick={handleSaveToggle}
              disabled={isLoading}
              className={isSaved ? 'text-primary' : ''}
            >
              <Bookmark className={isSaved ? 'fill-current' : ''} />
            </Button>
          </div>

          <div className="mt-4 flex flex-wrap items-center gap-4">
            <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
              <MapPin className="h-4 w-4" />
              <span>{job.location}</span>
              {job.remote && (
                <span className="ml-2 rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800 dark:bg-blue-900/30 dark:text-blue-500">
                  Remote
                </span>
              )}
            </div>
            <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
              <DollarSign className="h-4 w-4" />
              <span>
                {job.salary.currency} {job.salary.min.toLocaleString()} - {job.salary.max.toLocaleString()}
              </span>
            </div>
            <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
              <Briefcase className="h-4 w-4" />
              <span>{job.type}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
              <Clock className="h-4 w-4" />
              <span>{formatDistanceToNow(new Date(job.postedAt))} ago</span>
            </div>
          </div>

          <div className="mt-4 flex flex-wrap gap-2">
            {job.skills.slice(0, 5).map((skill) => (
              <span
                key={skill}
                className="rounded-full bg-primary/10 px-3 py-1 text-sm text-primary"
              >
                {skill}
              </span>
            ))}
            {job.skills.length > 5 && (
              <span className="rounded-full bg-gray-100 px-3 py-1 text-sm text-gray-600 dark:bg-gray-800 dark:text-gray-400">
                +{job.skills.length - 5} more
              </span>
            )}
          </div>

          <div className="mt-6 flex items-center justify-between">
            <div className="flex items-center gap-4">
              {job.benefits.length > 0 && (
                <div className="flex items-center gap-2">
                  <span className="text-sm text-gray-600 dark:text-gray-400">Benefits:</span>
                  <div className="flex gap-2">
                    {job.benefits.slice(0, 2).map((benefit) => (
                      <span
                        key={benefit}
                        className="rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 dark:bg-green-900/30 dark:text-green-500"
                      >
                        {benefit}
                      </span>
                    ))}
                    {job.benefits.length > 2 && (
                      <span className="text-sm text-gray-600 dark:text-gray-400">
                        +{job.benefits.length - 2} more
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <Link to={`/jobs/${job.id}`}>
              <Button>View Details</Button>
            </Link>
          </div>
        </div>
      </div>
    </Card>
  );
}