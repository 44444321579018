import { useState } from 'react';
import { JobList } from '@/components/jobs/job-list';
import { JobFilters } from '@/components/jobs/job-filters';
import { JobSearch } from '@/components/jobs/job-search';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@/lib/store';
import { JobType, ExperienceLevel } from '@/types/job';
import { config } from '@/lib/config';

const defaultFilters = {
  types: [] as JobType[],
  levels: [] as ExperienceLevel[],
  locations: [] as string[],
  salary: {
    min: 0,
    max: 200000
  },
  remote: false,
  skills: [] as string[]
};

export function JobsPage() {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState(defaultFilters);

  const handleCreateJob = () => {
    if (!config.supabaseUrl || !config.supabaseAnonKey) {
      alert('Please connect to Supabase first by clicking the "Connect to Supabase" button in the top right.');
      return;
    }

    if (user?.profile?.type === 'company' && user.profile.verified) {
      navigate('/jobs/create');
    } else {
      navigate('/company/verification');
    }
  };

  if (!config.supabaseUrl || !config.supabaseAnonKey) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16">
        <div className="container mx-auto px-4 py-8">
          <div className="rounded-lg border bg-white p-8 text-center shadow-sm">
            <h1 className="text-2xl font-bold text-gray-900">Connect to Supabase</h1>
            <p className="mt-2 text-gray-600">
              Please connect to Supabase first by clicking the "Connect to Supabase" button in the top right.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="container mx-auto px-4 py-8">
        <div className="mb-8 flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-bold">AI Jobs</h1>
            <p className="mt-2 text-gray-600">
              Find your next opportunity in artificial intelligence
            </p>
          </div>
          {user?.profile?.type === 'company' && (
            <Button onClick={handleCreateJob}>
              <Plus className="mr-2 h-5 w-5" />
              Post a Job
            </Button>
          )}
        </div>

        <div className="grid gap-8 lg:grid-cols-[280px_1fr]">
          <aside>
            <JobFilters filters={filters} onFilterChange={setFilters} />
          </aside>

          <main>
            <JobSearch value={searchQuery} onChange={setSearchQuery} />
            <JobList searchQuery={searchQuery} filters={filters} />
          </main>
        </div>
      </div>
    </div>
  );
}